var site = site || {};
site.template = site.template || {};
site.templates = site.templates || {};
var defaults = defaults || {};
defaults.globals = defaults.globals || {};

(function($){
  site.template.render = function(template, data) {
    defaults.globals.t = site.translations;
    data = data || {};

    // You can pass just the template as a string if you want:
    if (_.isString(template)) {
      template = {
        content: template,
        data: {}
      };
    }

    var view = $.extend({}, defaults, template.data, data);
    var partials = {};

    if (!_.isUndefined(template.partials)) {
      $.each(template.partials, function(key, name) {
        if (_.isUndefined(site.templates[key]) && _.isUndefined(site.templates[name])) {
          console.log('The partial ' + key + ' or ' + name + ' cannot be found');
        }

        var pkey = (!_.isUndefined(site.templates[key])) ? key : name;
        partials[pkey] = site.templates[pkey].content;
      });
    }

    return Mustache.render(template.content, view, partials);
  };

  site.template.get = function(args) {
    var template = site.templates[args.name];
    if (_.isUndefined(template)) {
      console.log('The template ' + args.name + ' cannot be found');
    }
    var data = {
      globals: {
        t: site.translations,
        variables: {
          // IE doesn't support location.origin, so...
          site_url: window.location.protocol + '//' + window.location.hostname,
          country_id: Drupal.settings.country_id,//Getting the country ID as a variable for templates
          language_id: Drupal.settings.language_id // getting the language ID in templates
        }
      }
    };

    // include config settings from bb_product module
    if (!_.isUndefined(Drupal) && !_.isUndefined(Drupal.settings) && !_.isUndefined(Drupal.settings.bb_product)) {
      $.extend(data.globals.variables, Drupal.settings.bb_product);
    }
    if (!_.isUndefined(Drupal.settings.birth_data)) {
      $.extend(data.globals.variables,  Drupal.settings.birth_data);
    }
    if (!_.isUndefined(Drupal.settings.product_display)) {
      $.extend(data.globals.variables, Drupal.settings.product_display);
    }

    var view = $.extend({}, args.data, data, template.data);
    var partials = {};

    if (!_.isUndefined(template.partials)) {
      $.each(template.partials, function(key, name) {
        if (_.isUndefined(site.templates[name])) {
          throw new Error('The partial ' + name + ' cannot be found');
        }

        partials[key] = site.templates[name].content;
      });
    }

    var rendered = Mustache.render(template.content, view, partials);

    if (_.isFunction(args.callback)) {
      var so = args.callback(rendered);
      if (!_.isUndefined(so)) {
        return so;
      }
    }

    return rendered;
  };
})(jQuery);
